<template>
  <div class="availability-reservation-sidebar">
    <div class="availability-reservation-sidebar--content">
      <p v-if="vehicle.vehicleName" style="font-size: 14px; font-weight: 600">
        {{ vehicle.vehicleName }}
      </p>
      <div v-if="reservations.length" class="availability-subsection">
        <div
          class="availability-subsection--subheader"
          style="padding-bottom: 10px"
        >
          <CRIcon
            :key="`check-icon`"
            view-box="0 0 24 24"
            :width="16"
            :height="16"
          >
            check
          </CRIcon>
          <p class="availability-subsection--subheader--text">
            Accepted ({{ reservations.length }})
          </p>
        </div>
        <div
          v-for="reservation in reservations"
          :key="`reservation-${reservation.reservationId}`"
          class="availability-subsection--reservation"
        >
          <div>
            <p class="availability-subsection--reservation--header">
              ID
              <router-link
                :id="`reservation-link-${reservation.reservationId}`"
                style="text-decoration: none"
                target="_blank"
                :to="{
                  name: 'reservation-detail',
                  params: { id: reservation.parentReservationId },
                }"
              >
                #{{ reservation.managedReservationId }}
              </router-link>
            </p>
            <p>Pickup Location: {{ reservation.pickupLocation }}</p>
            <p>
              Pickup Time: {{ reservation.startDate }}
              {{ reservation.pickupTime }}
            </p>
            <p>
              Dropoff Time: {{ reservation.endDate }}
              {{ reservation.dropoffTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
    reservations: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style lang="scss" scoped>
.availability-reservation-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }
}

.availability-subsection {
  font-size: 10px;

  &--subheader {
    display: flex;
    align-items: center;

    &--text {
      font-size: 10px;
      padding-left: 10px;
      margin: 0;
      font-weight: 600;
    }
  }
  &--reservation {
    padding-left: 30px;
    padding-bottom: 20px;

    p {
      margin-bottom: 0;
    }

    &--header {
      font-weight: 600;
      text-decoration: none !important;
    }
  }
}
</style>
